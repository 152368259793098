import { IlangList } from '../../containers/AudioPlayerContainer/audioPlayerTypes';

export const langList: IlangList = {
  ch: 'Chinese',
  ger: 'German',
  fr: 'French',
  esp: 'Spanish',
  kor: 'Korean',
  eng: 'English',
  jap: 'Japanese',
  ru: 'Russian',
};
