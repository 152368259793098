import { Popup, Swiper } from 'antd-mobile';
import React, { FunctionComponent, ReactElement, useCallback } from 'react';
import './styles/index.css';
import { popupBodyStyle, swiperIndicatorProps } from './styles/styles';

interface Iprops {
  visible: boolean;
  setVisible: (v: boolean) => void;
  children: ReactElement;
}

const ModalContainer: FunctionComponent<Iprops> = ({ visible, setVisible, children }) => {
  const handleClosePopup = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Popup
      visible={visible}
      onMaskClick={handleClosePopup}
      bodyStyle={popupBodyStyle}
      bodyClassName="modal-container"
    >
      <button type="button" className="modal-container__close-button" onClick={handleClosePopup} />
      {children}
    </Popup>
  );
};

export { ModalContainer };
