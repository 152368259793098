import { FC } from 'react';
import './index.css';

interface ShowNameProps {
  name: string;
  isOnMain?: boolean;
}

// BOPE  CU  GS  HBM  JTSS  MOE  POTU  SWAUTUMN  SWSPRING  SWSUMMER  SWWINTER  WAS

interface Ialpha {
  [p: string]: string;
}

const alpha: Ialpha = {
  bope: 'Birth of the planet Earth',
  cu: 'Color Universe',
  gs: 'Guiding Stars',
  hbm: 'Habbitabble Moon',
  jtss: 'Journey to the Solar System',
  moe: 'Moving of the Earth',
  potu: 'Phantom of Universe',
  swautumn: 'Seasons: Autumn',
  swspring: 'Seasons: Spring',
  swsummer: 'Seasons: Summer',
  swwinter: 'Seasons: Winter',
  was: 'We are stars',
  sim: 'Sky In Motion',
  lq: 'Lifes question',
  bts: 'Beyond The Sun',
};

const ShowName: FC<ShowNameProps> = ({ name, isOnMain = true }) => (
  <article className="audio-player-show-name-container">
    <p className="audio-player-show-name">{alpha[name] || name}</p>
    {isOnMain && <span className="audio-player-info-text">is going on right now</span>}
  </article>
);

export { ShowName };
