import { FC } from 'react';
import './index.css';

interface infoTextProps {
  text: string;
  classes?: string[];
}

const InfoText: FC<infoTextProps> = ({ text, classes }) => {
  return <p className={`info-text ${classes?.join(' ')}`}>{text}</p>;
};

export { InfoText };
