import { FC } from 'react';
import './index.css';
import classNames from 'classnames';

interface PlayButtonProps {
  type: 'play' | 'stop';
  handleClick?: () => void;
  small?: boolean;
  animation?: boolean;
}

const PlayerButton: FC<PlayButtonProps> = ({ handleClick, small, animation, type }) => {
  const playButtonClasses = classNames('player-button', {
    'player-button_type_play': type === 'play',
    'player-button_type_stop': type === 'stop',
    'play-button_small': small,
    'player-button_animated': animation,
    'player-button_type_play-animated': animation && type === 'play',
    'player-button_type_stop-animated': animation && type === 'stop',
  });

  return <button type="button" onClick={handleClick} className={playButtonClasses} />;
};

export { PlayerButton };
