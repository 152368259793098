export const howItWorksTexts = {
  beforeStart:
    'Before the start of the show program, there will be an announcement about fire safety and a small advertisement in the hall.',
  whenShowStarts:
    'When the show starts, the name of the program will be displayed at the top of the screen.',
  buttonsWillBeDisplayed:
    'Buttons will be displayed at the bottom of the screen. To select a language, click on the button with the required language:',
  afterClickingLang:
    'After clicking on the required language, when the download is completed, the play button will appear.',
  pressPlayBtn: 'Press the play button',
  toStartPlaying: 'to start playing the translation',
  youCanStop: 'if needed, you can stop playback using the stop button.',
  consistOfTwoParts:
    'most of our show programs consist of two parts, so after completing the first part of the program, you will need to select the language again',
};
