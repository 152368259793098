import React, { FC, useEffect, useRef, useState } from 'react';
import { currentFilm } from './audioPlayerTypes';
import AudioPlayerAvailableLanguages from '../../components/AvailableLanguages';
import { InfoText } from '../../components/InfoText/InfoText';
import './index.css';
import ShowName from '../../components/ShowName';
import PlayerButton from '../../components/PlayerButton';

interface IaudioPlayerProps {
  isLoading: boolean;
  setIsLoading: (s: boolean) => void;
  setLoadProgress: (p: number) => void;
}

export const AudioPlayerContainer: FC<IaudioPlayerProps> = ({
  isLoading,
  setIsLoading,
  setLoadProgress,
}) => {
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [currentFilm, setCurrentFilm] = useState<currentFilm>({
    available_languages: [],
    end_time_ms: 0,
    show_name: '',
    start_time_ms: 0,
  });
  const [chosenLanguage, setChosenLanguage] = useState<string>('');
  const [canPlay, setCanPlay] = useState<boolean>(false);
  const [isGoing, setIsGoing] = useState<boolean>(false);
  const [player] = useState<HTMLAudioElement | null>(null);
  const desktopAudio = useRef<HTMLAudioElement | null>(null);
  const mobileAudio = useRef<HTMLAudioElement | null>(null);
  const btnDoNothing = useRef<HTMLButtonElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  // 127.0.0.1:8080

  // localhost:8080

  // 91.229.221.53

  const address =
    window.location.host === 'localhost:3000' ? '91.229.221.53' : window.location.host;

  // const address = '91.229.221.53';

  // console.log(address);

  useEffect(() => {
    connectSocket();
    fetch(`http://${address}/api/narrations`)
      .then((response) => {
        if (response.status === 204) {
          setIsGoing(false);
          return;
        }
        return response.json();
      })
      .then((film: currentFilm) => {
        if (film) {
          handleFilmInfo(film);
        }
      })
      .catch((err) => console.log(err));

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) return;
      connectSocket();
    });
  }, []);

  useEffect(() => {
    if (currentFilm && chosenLanguage) {
      setIsGoing(true);
      getAudioFile(chosenLanguage);
    }
    checkIsGoing();
  }, [chosenLanguage, currentFilm]);

  const checkIsGoing = () => {
    const now = Date.now();
    if (now > currentFilm.end_time_ms) {
      setIsGoing(false);
      setCanPlay(false);
      return;
    }
  };

  const startAudio = () => {
    playAudio(desktopAudio.current);
    playAudio(mobileAudio.current);
  };

  function playAudio(audioPlayerRef: HTMLAudioElement | null) {
    if (!audioPlayerRef) {
      return;
    }
    // console.log(desktopAudio.current, mobileAudio.current);
    audioPlayerRef.volume = 1;
    audioPlayerRef.currentTime = getCurrentTiming();
    audioPlayerRef?.load();
    audioPlayerRef
      ?.play()
      .then(() => {
        audioPlayerRef.currentTime = getCurrentTiming();
        setIsPlaying(true);
      })
      .catch((error: any) => {
        // alert(error);
        setIsPlaying(false);
      });
  }

  async function newPlayAudio() {
    if (desktopAudio.current) desktopAudio.current.load();
    if (mobileAudio.current) mobileAudio.current.load();
    if (mobileAudio.current) {
      mobileAudio.current.volume = 1;
      mobileAudio.current.currentTime = getCurrentTiming();
      // console.log(mobileAudio.current.currentTime);
      // mobileAudio.current.load();
      // console.log(mobileAudio.current.currentTime);
      try {
        await mobileAudio.current.play();
        mobileAudio.current.currentTime = getCurrentTiming();
        setIsPlaying(true);
      } catch (error) {
        if (desktopAudio.current) {
          desktopAudio.current.volume = 1;
          desktopAudio.current.currentTime = getCurrentTiming();
          // console.log(desktopAudio.current.currentTime);
          // desktopAudio.current.load();
          // console.log(desktopAudio.current.currentTime);
          try {
            await desktopAudio.current.play();
            desktopAudio.current.currentTime = getCurrentTiming();
            setIsPlaying(true);
          } catch (error) {
            setIsPlaying(false);
            setChosenLanguage('');
            // console.log('НИ ОДИН ПЛЕЕР НЕ СМОГ ЗАИГРАТЬ!');
          }
        }
      }
    }
  }

  function pauseAudio() {
    // player?.pause();
    desktopAudio.current?.pause();
    mobileAudio.current?.pause();
    setIsPlaying(false);
  }

  function getCurrentTiming() {
    const diff = new Date(Date.now() - currentFilm.start_time_ms);
    return diff.getMinutes() * 60 + diff.getSeconds();
  }

  function getAudioFile(lang: string) {
    setCanPlay(false);
    setIsLoading(true);
    const headers = new Headers();
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');
    let fullLength = 0;
    let receivedLength = 0;
    let waiting = false;
    fetch(`http://${address}/api/narrations/${lang}`)
      .then((response) => {
        if (!response.body) {
          console.log('no response body');
          return;
        }
        const reader = response.body.getReader();
        fullLength = Number(response.headers.get('Content-Length'));
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump(): Promise<void> {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }

                receivedLength += value.length;
                if (!waiting) {
                  setLoadProgress((receivedLength / fullLength) * 100);
                  waiting = true;
                } else
                  setTimeout(() => {
                    waiting = false;
                  }, 300);
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => {
        setAudioUrl(url);
        setCanPlay(true);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
          btnDoNothing.current?.click();
        }, 1000);
      });
  }

  function chooseLanguage(lang: string) {
    setChosenLanguage(lang);
    pauseAudio();
    btnDoNothing.current?.click();
  }

  function canPlayHandler() {
    setCanPlay(true);
  }

  function handleFilmInfo(film: currentFilm) {
    setChosenLanguage('');
    setIsGoing(true);
    setCurrentFilm(film);
    setCanPlay(false);
  }

  function connectSocket() {
    const socket = new WebSocket(`ws://${address}/api/ws`);
    socket.onmessage = (data) => {
      handleFilmInfo(JSON.parse(JSON.parse(data.data)));
    };
    socket.onopen = (event: any) => {
      console.log('socket opened:');
    };
  }

  return (
    <div className="translation-main-container">
      <button className="button-do-nothing" type="button" ref={btnDoNothing} />
      {isGoing ? (
        <>
          <audio ref={mobileAudio} onCanPlay={canPlayHandler} className={'audio-player'}>
            <source src={audioUrl} type="audio/mpeg" />
            <source src={audioUrl} type="audio/ogg" />
            <source src={audioUrl} type="mp3" />
          </audio>
          <audio
            className={'audio-player'}
            onCanPlay={canPlayHandler}
            ref={desktopAudio}
            src={audioUrl}
          />
          <div>
            <ShowName name={currentFilm.show_name} />
            {!chosenLanguage && <InfoText classes={['anim']} text={'Please select language:'} />}
          </div>
          {canPlay &&
            (isPlaying ? (
              <PlayerButton handleClick={pauseAudio} type="stop" animation={!isPlaying} />
            ) : (
              <PlayerButton handleClick={newPlayAudio} type="play" animation={isPlaying} />
            ))}
          <AudioPlayerAvailableLanguages
            languages={currentFilm.available_languages}
            chooseLanguage={chooseLanguage}
            chosenLanguage={chosenLanguage}
          />
        </>
      ) : (
        <InfoText
          classes={['anim']}
          text={'The Show has not been started yet. Please, wait for the beginning.'}
        />
      )}
    </div>
  );
};
