import { Swiper } from 'antd-mobile';
import React from 'react';
import { InfoText } from '../InfoText/InfoText';
import { howItWorksTexts } from './HowItWorksTexts';
import AudioPlayerShowName from '../ShowName';
import './index.css';
import LanguageButton from '../LanguageButton';
import PlayButton from '../PlayerButton';
import { swiperIndicatorProps } from '../../containers/ModalContainer/styles/styles';

const swiperTemplates = [
  <div className="swiper-item swiper-item__align-items-center" key={1}>
    <InfoText text={howItWorksTexts.beforeStart} />
  </div>,
  <div className="swiper-item swiper-item__align-content-between" key={2}>
    <AudioPlayerShowName name={'Colorful planet'} />
    <InfoText text={howItWorksTexts.whenShowStarts} />
  </div>,
  <div className="swiper-item swiper-item__align-content-between" key={3}>
    <InfoText text={howItWorksTexts.buttonsWillBeDisplayed} />
    <div className="buttons-group">
      <LanguageButton lang={'eng'} />
      <LanguageButton lang={'ch'} />
      <LanguageButton lang={'ger'} />
    </div>
  </div>,
  <div className="swiper-item swiper-item__align-content-between" key={4}>
    <PlayButton animation={true} type={'play'} />
    <InfoText text={howItWorksTexts.afterClickingLang} />
  </div>,
  <div className="swiper-item swiper-item__align-content-between" key={5}>
    <div className="swiper-item__group">
      <InfoText text={howItWorksTexts.pressPlayBtn} />
      <PlayButton animation={true} type={'play'} small={true} />
      <InfoText text={howItWorksTexts.toStartPlaying} />
    </div>
    <div className="swiper-item__group" key={6}>
      <InfoText text={howItWorksTexts.youCanStop} />
      <PlayButton animation={true} type={'stop'} small={true} />
    </div>
  </div>,
  <div className="swiper-item swiper-item__align-items-center" key={7}>
    <InfoText text={howItWorksTexts.consistOfTwoParts} />
  </div>,
];

const HowItWorksTemplates = () => (
  <Swiper
    className="swiper"
    autoplay={false}
    autoplayInterval={5000}
    indicatorProps={{ style: swiperIndicatorProps }}
  >
    {swiperTemplates.map((el, index) => (
      <Swiper.Item key={index}>{el}</Swiper.Item>
    ))}
  </Swiper>
);

export { HowItWorksTemplates };
