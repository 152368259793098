import React, { useEffect, useState } from 'react';
import MainPage from './pages/MainPage';
import Header from './components/Header';
import './index.css';

function App() {
  const [howItWorksIsVisible, setHowItWorksIsVisible] = useState(false);

  return (
    <div className="App">
      <Header setVisible={setHowItWorksIsVisible} />
      <MainPage visible={howItWorksIsVisible} setVisible={setHowItWorksIsVisible} />
    </div>
  );
}

export default App;
