import React, { FC } from 'react';
import './index.css';
import classNames from 'classnames';
import loadingIcon from '../../assets/loading-gif.gif';
import { InfoText } from '../InfoText/InfoText';

interface ILoaderProps {
  visible: boolean;
  handleClose: () => void;
  loadProgress?: number;
}

export const Loader: FC<ILoaderProps> = ({ visible, handleClose, loadProgress = 40 }) => {
  const containerClasses = classNames('loader-container', {
    'loader-container_visible': visible,
  });
  return (
    <div className={containerClasses}>
      <button onClick={handleClose} type="button" className="loader-container__close-button" />
      <div className="loader-container__icon-box">
        <div
          className="loader-container__progress-bar"
          style={{
            background: `linear-gradient(90deg, #507661 ${loadProgress}%, transparent ${loadProgress}%)`,
          }}
        />
        <img className="loader-container__icon" src={loadingIcon} alt="loading-icon" />
      </div>
      <InfoText classes={['loading', 'up']} text="loading..." />
    </div>
  );
};
