import React, { FC, useCallback, useState } from 'react';
import AudioPlayerContainer from '../containers/AudioPlayerContainer';
import ModalContainer from '../containers/ModalContainer';
import { HowItWorksTemplates } from '../components/HowItWorksTemplates/HowItWorksTemplates';
import Loader from '../components/Loader';

interface Iprops {
  visible: boolean;
  setVisible: (v: boolean) => void;
}

const MainPage: FC<Iprops> = ({ visible, setVisible }) => {
  const [isLoaderVisible, setIsLoadingVisible] = useState(false);
  const [loadProgress, setLoadProgress] = useState(0);

  const handleCloseLoader = useCallback(() => {
    setIsLoadingVisible(false);
  }, []);

  return (
    <>
      <Loader
        handleClose={handleCloseLoader}
        visible={isLoaderVisible}
        loadProgress={loadProgress}
      />
      <AudioPlayerContainer
        isLoading={isLoaderVisible}
        setIsLoading={setIsLoadingVisible}
        setLoadProgress={setLoadProgress}
      />
      <ModalContainer visible={visible} setVisible={setVisible}>
        <HowItWorksTemplates />
      </ModalContainer>
    </>
  );
};

export default MainPage;
