import React, { FC } from 'react';
import './index.css';
import HowItWorksButton from '../HowItWorksButton';

interface Iprops {
  setVisible: (v: boolean) => void;
}

const Header: FC<Iprops> = ({ setVisible }) => {
  const handleWhatIsBrowser = () => {
    console.log('Yours browser info:' + ' \n' + navigator.userAgent);
  };

  return (
    <header className="header">
      <HowItWorksButton text="How it works" handleClick={() => setVisible(true)} />
    </header>
  );
};

export default Header;
