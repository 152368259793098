import React from 'react';
import { langList } from './utils';
import './index.css';
import classNames from 'classnames';

interface Iprops {
  lang: string;
  chooseLanguage?: (lang: string) => void;
  chosenLanguage?: string;
}

export const LanguageButton = ({ lang, chooseLanguage, chosenLanguage }: Iprops) => {
  const buttonClasses = classNames('language-button', {
    'language-button_active': chosenLanguage === lang,
    'language-button_not-active': !(chosenLanguage === lang) && !!chosenLanguage,
  });
  function handleClick() {
    if (chosenLanguage === lang || !chooseLanguage) return;
    chooseLanguage(lang);
  }
  return (
    <button onClick={handleClick} className={buttonClasses}>
      {langList[lang]}
    </button>
  );
};
