import './index.css';
import { FC } from 'react';

interface Iprops {
  text: string;
  handleClick: () => void;
}

const HowItWorksButton: FC<Iprops> = ({ text, handleClick }) => {
  return (
    <button onClick={handleClick} className="how-it-works-button">
      {text}
    </button>
  );
};

export { HowItWorksButton };
