import React from 'react';

const popupBodyStyle = {
  height: 'calc(100% - 49px)',
  boxSizing: 'border-box',
  paddingBottom: '15px',
  paddingTop: '90px',
} as React.CSSProperties;

const swiperIndicatorProps = {
  '--dot-color': 'rgba(154, 201, 219, 0.4)',
  '--active-dot-color': 'rgba(154, 201, 219, 1)',
  '--dot-size': '5px',
  '--active-dot-size': '20px',
  '--dot-border-radius': '0',
  '--active-dot-border-radius': '0',
  '--dot-spacing': '8px',
} as React.CSSProperties;

export { popupBodyStyle, swiperIndicatorProps };
