import React from 'react';
import { LanguageButton } from '../LanguageButton/LanguageButton';

interface Iprops {
  languages: string[];
  chooseLanguage: (language: string) => void;
  chosenLanguage: string;
}

export const AvailableLanguages = ({ languages, chooseLanguage, chosenLanguage }: Iprops) => {
  return (
    <div className="buttons-group">
      {languages?.map((lang, index) => (
        <LanguageButton
          key={index}
          chooseLanguage={chooseLanguage}
          lang={lang}
          chosenLanguage={chosenLanguage}
        />
      ))}
    </div>
  );
};
